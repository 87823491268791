.payment-card-container {
	display: flex;

	flex-wrap: wrap;
}

.add-card {
	z-index: 11;
	cursor: pointer;
}
.mt-09{
	margin-top : -0.9em !important;
}
.amount{
	margin-right: -12px;
}
/* .react-tel-input .form-control{
	font-size: 11px !important;
} */