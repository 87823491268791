@tailwind utilities;
@tailwind base;
@tailwind components;


@layer base {
  
  @font-face {
    font-family: "Lato-Bold";
    src: url("./fonts/Lato-Bold.ttf");
    font-weight: 900;
    font-style: normal;
  }
  @font-face {
    font-family: "Monsterract-Bold";
    src: url("./fonts/Montserrat/Montserrat-Bold.ttf");
    font-weight: 900;
    font-style: normal;
  }
  @font-face {
    font-family: "Monsterract-SemiBold";
    src: url("./fonts/Montserrat/Montserrat-SemiBold.ttf");
    font-weight: 900;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins";
    src: url("./fonts/Poppins/Poppins-Regular.ttf");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins-Medium";
    src: url("./fonts/Poppins/Poppins-Medium.ttf");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins-SemiBold";
    src: url("./fonts/Poppins/Poppins-SemiBold.ttf");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins-Bold";
    src: url("./fonts/Poppins/Poppins-Bold.ttf");
    font-weight: 900;
    font-style: normal;
  }
  @font-face {
    font-family: "Trashhand";
    src: url("./fonts/TrashHand.ttf");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: "Milloyste";
    src: url("./fonts/Milloyste.ttf");
    font-weight: 300;
    font-style: normal;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: #e6e6e6;
  color: #000;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
}

.switch.off,
.switch.on {
  border-radius: 25px;
  padding: 5px;
  cursor: pointer;
}

.switch.off:before,
.switch.off:after {
  -webkit-transition: 0.6s;
  transition: all 0.6s;
}

.switch.on:before,
.switch.on:after {
  -webkit-transition: 0.6s;
  transition: all 0.6s;
}

.switch.off:before,
.switch.on:before {
  display: block;
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0px;
  top: 0px;
  background-color: #d0a342;
  -webkit-transition: 0.6s;
  transition: all 0.6s;
  -webkit-transition-delay: 0.01s;
  transition-delay: 0.01s;
  -moz-box-shadow: inset -8px -8px 6px -6px #c7960f;
  -webkit-box-shadow: inset -8px -8px 6px -6px #c7960f;
  border-radius: 50%;
  box-shadow: inset -8px -8px 6px -6px #c7960f;
}

.switch.off:after {
  display: block;
  position: absolute;
  -o-transform: rotate(-270deg);
  -webkit-transform: rotate(-270deg);
  -ms-transform: rotate(-270deg);
  transform: rotate(-270deg);
  left: 17px;
  top: 15px;
}

.switch.on:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.switch.on:after {
  position: absolute;
  -o-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  left: 40px;
  top: 15px;
}

.ql-align-right {
  text-align: right;
}

.react-datepicker__close-icon::after{
  font-size: 14px;
  height: 16px;
  width: 16px;
  padding: 3px 5px;
}

.react-datepicker__close-icon {
  position: relative;
  top: 0;
  right: 31px;
}
