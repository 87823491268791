body {
  font-family: Poppins;
  background: #f6f6f6;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

h1 {
  font-family: 'Monsterract-Bold';
  font-weight: bold;
}
.blurBox {
  background-image: linear-gradient(to top, rgba(0, 255, 255, 1));
}
h4 {
  font-family: Poppins;
}

p {
  font-size: 1.25rem;
  color: #7e7e7e;
}

.news div:nth-child(1) {
  margin-left: 5rem;
}

.slidingVertical {
  display: inline;
  text-indent: 4px;
}

.slidingVertical span {
  animation: topToBottom 16s linear infinite 0s;
  -ms-animation: topToBottom 16s linear infinite 0s;
  -webkit-animation: topToBottom 16s linear infinite 0s;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}

.slidingVertical span:nth-child(2) {
  animation-delay: 1.5s;
  -ms-animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
}
.slidingVertical span:nth-child(3) {
  animation-delay: 3s;
  -ms-animation-delay: 3s;
  -webkit-animation-delay: 3s;
}
.slidingVertical span:nth-child(4) {
  animation-delay: 4.5s;
  -ms-animation-delay: 4.5s;
  -webkit-animation-delay: 4.5s;
}
.slidingVertical span:nth-child(5) {
  animation-delay: 6s;
  -ms-animation-delay: 6s;
  -webkit-animation-delay: 6s;
}
.slidingVertical span:nth-child(6) {
  animation-delay: 7.5s;
  -ms-animation-delay: 7.5s;
  -webkit-animation-delay: 7.5s;
}

.slidingVertical span:nth-child(7) {
  animation-delay: 9s;
  -ms-animation-delay: 9s;
  -webkit-animation-delay: 9s;
}
.slidingVertical span:nth-child(8) {
  animation-delay: 10.5s;
  -ms-animation-delay: 10.5s;
  -webkit-animation-delay: 10.5s;
}
.slidingVertical span:nth-child(9) {
  animation-delay: 13s;
  -ms-animation-delay: 13s;
  -webkit-animation-delay: 13s;
}
.slidingVertical span:nth-child(10) {
  animation-delay: 14.5s;
  -ms-animation-delay: 14.5s;
  -webkit-animation-delay: 14.5s;
}

.card-x-padding {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.toggle-schedule-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toggle-schedule-view > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.charity-partner-card-main {
  display: flex;
  justify-content: center;
}

.charity-partner-card-main > div {
  width: 344px;
  max-width: 100%;
}

.alert-beta-version {
  position: fixed;
  z-index: 99;
  background: #000000;
  color: #ffffff;
  width: 100%;
  text-align: center;
  padding: 5px 30px;
  font-size: 13px;
  height: 30px;
  overflow: hidden;
  top: 0;
  display: none;
}

.alert-beta-version span {
  font-size: 26px;
  line-height: 22px;
  margin: 0 8px;
  display: inline-block;
  vertical-align: middle;
}

.alert-beta-version span:nth-child(2) {
  font-size: 20px;
}

.alert-beta-visible {
  padding-top: 30px;
}

.alert-beta-version button {
  position: absolute;
  top: 2px;
  right: 30px;
  color: #d0a342;
}

.alert-beta-version button:hover,
.alert-beta-version button:active {
  outline: none;
  border: none;
  opacity: 0.85;
}

.alert-beta-visible .sticky {
  top: inherit;
}

.alert-beta-visible .sticky.bg-white {
  top: 30px;
  transition: ease 0.2s;
}

.alert-beta-visible .alert-beta-version {
  display: block;
}

.days-block-card > div.self-center {
  align-self: flex-start;
}
/* 
@media only screen and (max-width: 768px) {
  .learningCenter:nth-child(1) {
    margin-left: 1rem;
  }
}
@media only screen and (min-width: 768px) {
  .learningCenter:nth-child(1) {
    margin-left: -22rem;
  }
} */

/*topToBottom Animation*/
@keyframes topToBottom {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    transform: translateY(50px);
  }
  8% {
    opacity: 1;
    transform: translateY(0px);
  }
  14% {
    opacity: 1;
    transform: translateY(0px);
  }
  18% {
    opacity: 0;
    transform: translateY(-50px);
    /* transform: translateY(0px); */
  }
  30% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes topToBottom {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    -webkit-transform: translateY(50px);
  }
  8% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }
  14% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }
  18% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
    /* transform: translateY(0px); */
  }
  30% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-ms-keyframes topToBottom {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    -ms-transform: translateY(50px);
  }
  8% {
    opacity: 1;
    -ms-transform: translateY(0px);
  }
  14% {
    opacity: 1;
    -ms-transform: translateY(0px);
  }
  18% {
    opacity: 0;
    -ms-transform: translateY(-50px);
    /* transform: translateY(0px); */
  }
  30% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.DatePicker__calendarContainer {
  margin-top: 5rem;
}

.cm-paging {
  position: absolute;
  bottom: -25px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.cm-paging li {
  position: relative;

  display: inline-block;

  width: 30px;
  height: 30px;
  margin: 0 5px;
  padding: 0;

  cursor: pointer;
}
.cm-paging li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 30px;
  height: 30px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.cm-paging li button:hover,
.cm-paging li button:focus {
  outline: none;
}
.cm-paging li button:hover:before,
.cm-paging li button:focus:before {
  opacity: 1;
}
.cm-paging li button:before {
  font-family: 'slick';
  font-size: 14px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: '•';
  text-align: center;

  opacity: 1;
  color: #d0a342;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cm-paging li.slick-active button:before {
  /* font-size: 24px;
    line-height: 24px; */
  opacity: 1;
  border-radius: 5px;
  width: 30px;
  height: 12px;
  margin-right: 30px;
  margin-top: 3.5px;
  background-color: #d0a342;
  border: solid #d0a342;
  overflow-y: hidden;
  content: '';
  color: #d0a342;
}

.slick-list {
  margin: 0 -10px;
}
.slick-slide > div {
  padding-right: 15px;
  padding-left: 15px;
}

.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #d0a342;
  opacity: 0.4;
}
.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 1.5s infinite;
  content: '';
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.DatePicker__calendarContainer {
  margin-top: 0;
}

.x {
  position: absolute;
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 50%;
}
.x::before,
.x::after {
  display: block;
  position: absolute;
  top: 10px;
  left: 5px;
  width: 12px;
  height: 3px;
  content: '';
  background-color: #d0a342;
  display: none;
}
.x::before {
  display: block;
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.x::after {
  display: block;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.x:hover {
  cursor: pointer;
}
.x:hover::before,
.x:hover::after {
  display: block;
}

/*Switch Component: */
div.sc-bdVaJa.esqzsP::before {
  border-radius: 0.3rem;
  z-index: 1 !important;
}
div.sc-bdVaJa.esqzsP::after {
  border-radius: 0.3rem;
  z-index: 1 !important;
}
div.sc-bdVaJa.kMJDCy::after {
  border-radius: 0.3rem;
  background: url('../assets/icons/check.svg') no-repeat 100% 50%, #fff !important;
  z-index: 1 !important;
}
div.sc-bdVaJa.kMJDCy::before {
  background-color: #68d56f !important;
  border-radius: 0.3rem;
  z-index: 1 !important;
}

/* DateInput */

input.my-custom-input-class.z-30.relative {
  background: url('../assets/icons/chevronDown.svg') no-repeat 95% 50%;
}
/* .DatePicker__calendarContainer{
  transform: translate(7rem, -3.4rem) !important;
} */
.DatePicker {
  z-index: unset !important;
}

.DatePicker__calendarContainer {
  z-index: 100000 !important;
}
/* Credit Card Input */

.sc-htpNat {
  border: none !important;
}
.cHycrd {
  border-radius: 0.5rem !important;
}

/* Info Box with arrow */

.box {
  width: 25rem;
  cursor: default;
  height: auto;
  background-color: #d0a342;
  border-radius: 0.75rem;
  color: #fff;
  padding: 20px;
  position: relative;
  margin: 40px;
  margin-bottom: 0;
  float: left;
  transform-origin: bottom left;
}

.box.arrow-top {
  cursor: default;
  margin-top: 40px;
}

@media only screen and (max-width: 1280px) {
  .box.arrow-bottom {
    width: 18rem;
    margin-left: -3.5rem;
  }
}

@media only screen and (max-width: 343px) {
  .box.arrow-bottom {
    width: 15rem;
    margin-left: -0.5rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 1280px) {
  .box.arrow-right {
    position: fixed;
    width: 22rem;
    margin-left: 1rem;
    margin-top: -0.1rem;
  }
  .don-sch-block-main {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .dontation-schedule-block {
    flex: 1;
    flex-basis: 280px;
    max-width: calc(32.9% - 0.75rem);
  }
  .don-sch-block-main .dontation-schedule-block:last-child {
    margin-right: 0;
  }
  .days-block-card > span:first-child {
    flex: 1;
  }
}

@media only screen and (min-width: 1024px) {
  .fixed-menu-col {
    position: fixed;
    width: 22.281%;
  }
  .fixed-header {
    position: fixed;
    top: 0;
    z-index: 99;
    padding-bottom: 20px;
    background: #f6f6f6;
  }
  .thankyou-header {
    margin-top: 75px;
    z-index: 99;
  }

  body.sticky-menu {
    padding-top: 76px;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1458px) {
  .don-sch-block-main .dontation-schedule-block:last-child {
    margin-right: 1.5rem;
  }
  .don-sch-block-main .dontation-schedule-block:nth-child(2) {
    margin-right: 0;
  }
  .dontation-schedule-block {
    max-width: calc(50% - 0.75rem);
  }
}

@media only screen and (max-width: 1280px) {
  .box.arrow-right {
    position: fixed;
    width: 16rem;
    margin-left: -6rem;
    margin-top: 0rem;
  }
}

@media only screen and (max-width: 343px) {
  .box.arrow-right {
    position: fixed;
    width: 12rem;
    margin-left: -2rem;
    margin-top: 0rem;
  }
}

.box.arrow-bottom:after {
  cursor: default;
  content: ' ';
  position: absolute;
  right: 30px;
  bottom: -15px;
  border-top: 15px solid #d0a342;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: none;
}

.box.arrow-right:after {
  content: ' ';
  position: absolute;
  right: -15px;
  top: 15px;
  border-top: 15px solid transparent;
  border-right: none;
  border-left: 15px solid #d0a342;
  border-bottom: 15px solid transparent;
}

/* Radio button */

.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  font-weight: normal;
}

.radio-item label:before {
  content: ' ';
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #71abee;
  background-color: transparent;
}

.radio-item input[type='radio']:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: ' ';
  display: block;
  background: #71abee;
}

.email-alert-input::placeholder {
  color: red;
}

.swal2-styled.swal2-confirm {
  display: inline-block;
  background: #d3a94f !important;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
  color: #ffffff;
  box-shadow: 1px 2px 4px #7e5e185e;
}

.min-h-540 {
  min-height: 540px;
}

.min-h-570 {
  min-height: 570px;
}

.fq-items p {
  height: auto;
  overflow: hidden;
}
.freq-card-title ~ button {
  bottom: 30px;
}

@media only screen and (min-width: 1200px) {
  .fq-items p {
    height: 9rem;
  }
}

@media only screen and (min-width: 1920px) {
  .fq-items p {
    height: 10.5rem;
  }
}

.mw-170 {
  min-width: 170px;
}

.react-tel-input .form-control {
  font-family: Poppins;
  font-weight: normal;
  font-size: 16px !important;
  max-width: 100%;
}

@media screen and (min-width: 1280px) and (max-width: 1535px) {
  .freq-card-main {
    background-position-y: top !important;
  }
  .freq-card-title {
    font-size: 1.25rem;
  }
  .freq-card-title ~ p {
    font-size: 1rem;
    line-height: 24px;
  }
  .freq-card-title ~ button {
    bottom: 7px;
  }
  .top-landing-content {
    height: auto;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1460px) {
  .freq-card-title {
    font-size: 1rem;
  }
  .freq-card-title ~ p {
    font-size: 0.9rem;
    line-height: 22px;
  }
  .freq-card-title ~ button {
    bottom: 20px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1400px) {
  .freq-card-title ~ p {
    font-size: 0.85rem;
    line-height: 22px;
  }
  .freq-card-title ~ button {
    bottom: 30px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1350px) {
  .freq-card-title ~ p {
    font-size: 0.8rem;
    line-height: 22px;
  }
  .freq-card-title ~ button {
    bottom: 40px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1320px) {
  .freq-card-title ~ p {
    font-size: 0.8rem;
    line-height: 22px;
  }
  .freq-card-title ~ button {
    bottom: 45px;
  }
}

@media only screen and (max-width: 1500px) {
  .react-tel-input .form-control {
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 767px) {
  .react-tel-input .form-control {
    padding-left: 40px !important;
  }
  .page-content-cnt {
    margin-bottom: 0.5rem;
  }
  .days-title-text {
    font-size: 18px;
  }
  .days-item-cnt {
    display: flex;
    align-items: center;
  }
  .days-item-cnt > div {
    margin-right: 0;
    margin-left: auto;
    height: 22px;
  }
  .days-item-cnt > p,
  .days-item-title-text {
    font-size: 13px;
  }
  .page-content-main {
    margin-top: 20px;
  }
  .checkout-datepicker-cnt .my-custom-input-class {
    width: 8.5rem !important;
  }
  .flex-reverse {
    display: flex;
    flex-direction: column-reverse;
  }
  .page-title-text {
    margin-bottom: 10px;
  }
  .note-small-text {
    font-size: 1.2rem;
  }
  .sche-for-donate {
    padding-bottom: 24px;
  }
  .alert-beta-visible .alert-beta-version {
    text-align: left;
    height: auto;
    padding: 15px 60px 15px 15px;
  }
  .alert-beta-version button {
    top: 15px;
    right: 15px;
  }
  .alert-beta-visible .sticky.bg-white {
    top: 91px;
  }
  .alert-beta-visible {
    padding-top: 91px;
  }
  .tran-hist-wrapp {
    flex-direction: column;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  #howitworks .lg\:max-w-lg {
    max-width: 30rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .top-landing-content {
    display: flex;
  }
  .top-landing-content > div {
    flex: 1;
  }
  .top-landing-content > div:first-child {
    padding-left: 0;
  }
  .top-landing-content > div:first-child h1.sentence {
    font-size: 1.375rem;
  }
  .top-landing-content > div:first-child h3 {
    font-size: 1.2rem;
  }
  .top-landing-content > div:first-child h4 {
    font-size: 1rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .freq-card-main {
    width: 630px;
    margin: 0 auto 20px;
    background-size: 100% !important;
    background-position-y: top;
  }
  .freq-card-inner {
    width: 100%;
    height: 24rem;
  }
  .freq-card-title {
    font-size: 22px;
  }
  .freq-card-title ~ p {
    font-size: 18px;
    line-height: 30px;
  }
  .freq-card-outer {
    margin-top: 20px;
  }
  .base-title-text {
    font-size: 1.4rem;
  }
  .personal-days-row {
    width: 13rem;
  }
  .personal-days-row > div:first-child .days-item-cnt {
    display: flex;
    align-items: flex-start;
  }
  .flex-reverse {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 40px;
  }
  .page-title-text {
    margin-bottom: 20px;
  }
  .sche-for-donate {
    padding-bottom: 24px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .charity-counter {
    display: flex;
    align-items: flex-start;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .charity-counter > div {
    flex: 1;
    width: auto;
  }
  .charity-counter > div > p:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .learn-center-btm-card {
    display: flex;
    align-items: flex-start;
    margin: 0 20px;
  }

  .charity-m-card-main {
    display: flex;
    align-items: inherit;
    margin: 0;
    padding-left: 15px;
    padding-right: 15px;
  }

  .charity-m-card {
    flex: 1;
    padding: 15px;
  }

  .charity-m-card img {
    width: 2.5rem;
  }
  .alert-beta-version {
    height: auto;
  }
  .alert-beta-visible {
    padding-top: 52px;
  }
  .alert-beta-visible .sticky.bg-white {
    top: 52px;
    transition: ease-in-out 0.2s;
  }
}

@media only screen and (max-width: 600px) {
  .swal2-title {
    font-size: 1.3rem !important;
  }
  .swal2-input {
    font-size: 0.8rem !important;
    padding: 0 1rem 0 1.9rem !important;
  }
  .swal2-content {
    padding: 0rem !important;
  }
  .swal2-close {
    transform: scale(0.7) !important;
    top: 0.2rem !important;
  }
  .emailIcon {
    position: absolute !important;
    top: 5rem !important;
    width: 20px !important;
    left: 1.5rem !important;
  }
  .passwordIcon {
    position: absolute !important;
    top: 8.5rem !important;
    width: 25px !important;
    left: 1.5rem !important;
  }
  .memberLoginForgotPassword {
    font-size: 0.9rem !important;
  }
  .swal2-confirm {
    width: 7rem !important;
    height: 2.5rem !important;
    padding: 0 !important;
    font-size: 1rem !important;
  }
}

@media only screen and (min-width: 600px) {
  .swal2-title {
    font-size: 1.5rem !important;
  }
  .emailIcon {
    position: absolute !important;
    top: 5.8rem !important;
    width: 20px !important;
    left: 3.5rem !important;
  }
  .passwordIcon {
    position: absolute !important;
    top: 10.3rem !important;
    width: 25px !important;
    left: 3.5rem !important;
  }
  .swal2-input {
    font-size: 1rem !important;
    padding: 0 2.1rem !important;
  }
}

@media screen and (max-width: 480px) {
  .learn-center-card {
    padding-right: 0;
    overflow: hidden;
    height: 17rem;
  }
  .learn-center-card > div:first-child {
    width: 100%;
  }
  .learn-center-card > div:first-child img {
    width: 100%;
    height: 8rem;
    border-radius: 0;
    position: relative;
  }
  .learn-center-content {
    height: auto;
    padding: 0 15px 10px;
  }
  .learn-center-content > p {
    margin-left: 0;
  }
  .charity-partner-card-main {
    flex-direction: column;
  }
  .charity-partner-card-main > div {
    margin-left: 0;
    margin-right: 0;
  }
  a {
    word-break: break-word;
  }
}

@media screen and (max-width: 375px) {
  .freq-card-main {
    background-position-y: top !important;
  }

  .freq-card-main > div {
    margin-bottom: 0;
  }

  .freq-card-main:first-child {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .freq-card-title {
    padding-top: 0 !important;
  }

  .freq-card-title ~ button {
    bottom: 35px;
  }
  .freq-card-outer {
    margin-bottom: 10px;
  }
  .learn-center-card > div:first-child img {
    height: 7rem;
  }
  .charity-m-card {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .alert-beta-visible .alert-beta-version {
    padding-right: 40px;
  }
}

@media screen and (max-width: 360px) {
  .freq-card-inner {
    height: 13rem;
    padding: 10px;
  }
  .freq-card-title {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .freq-card-title ~ p {
    padding-left: 0;
    padding-right: 0;
  }
  .freq-card-title ~ button {
    bottom: 28px;
  }
  .base-title-text {
    font-size: 15px;
    margin-left: 10px;
  }
  .btn-res-spacing {
    padding-left: 20px;
    padding-right: 20px;
  }

  .days-block-card > span > span {
    font-size: 13px;
  }

  .days-block-card > span > span.text-base {
    font-size: 0.9rem;
  }
  .days-block-card .text-darkGreen {
    font-size: 1rem;
  }
  .dontation-schedule-block > p {
    font-size: 1rem;
  }
  .quote-text-cnt {
    padding: 0 10px;
  }
  .cover-transaction-fee label {
    max-width: 120px;
  }
  .learn-center-card {
    height: 16.5rem;
  }
  .alert-beta-visible .alert-beta-version {
    padding-right: 50px;
  }
}

@media screen and (max-width: 320px) {
  .freq-card-inner {
    height: 11.5rem;
    padding: 7px 10px;
  }
  .freq-card-title ~ p {
    font-size: 0.65rem;
    line-height: 14px;
  }
  .freq-card-title {
    font-size: 14px;
    padding-bottom: 3px;
  }
  .freq-card-title ~ button {
    bottom: 27px;
  }
  .base-title-text {
    font-size: 14px;
  }
  .days-item-cnt > p,
  .days-item-title-text {
    font-size: 12px;
  }
  .days-block-card > span > span.text-base {
    font-size: 0.8rem;
    line-height: normal;
  }
  .days-block-card > span > span {
    font-size: 11px;
  }
  .dontation-schedule-block > p {
    font-size: 0.85rem;
  }
  .note-small-text {
    font-size: 1rem;
  }
  .alert-beta-visible .sticky.bg-white {
    top: 111px;
  }
  .alert-beta-visible {
    padding-top: 111px;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .freq-card-main {
    width: 450px;
    margin: 0 auto 20px;
    background-size: 100% !important;
    background-position-y: top;
  }
  .freq-card-inner {
    width: 100%;
    height: 17rem;
  }
  .freq-card-title {
    font-size: 16px;
  }
  .freq-card-title ~ p {
    font-size: 14px;
    line-height: 22px;
  }
  .freq-card-outer {
    margin-top: 20px;
  }
  .left-none {
    margin-left: 0;
  }
  .alert-beta-visible .sticky.bg-white {
    top: 72px;
  }
  .alert-beta-visible {
    padding-top: 72px;
  }
}

@media screen and (max-width: 425px) {
  .alert-beta-visible .alert-beta-version ~ .landing-page #howitworks {
    padding-top: 100px;
    margin-top: -100px;
  }
  .freq-card-main:first-child {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
}

@media screen and (max-width: 480px) {
  .alert-beta-visible .alert-beta-version ~ .landing-page #howitworks {
    padding-top: 100px;
    margin-top: -100px;
  }
  .freq-card-inner {
    margin: auto;
  }
  .freq-card-main:first-child {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
}



button.beta-cross-btn::after {
  content: '\2716';
  font-size: 18px;
}

.slick-list {
  margin: 0 !important;
}

.cm-paginator-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
}
.cm-paginator-wrapper div {
  margin-right: 10px;
}
.page-status-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}